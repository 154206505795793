@import '../variable.scss';

.divider-container {
  width: 100%;
  height: 1px;
  background: $primary-border-color;
  opacity: 0.1;
}

@media screen and (max-width: $breakpoint-mobile) {
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
