@import '../variable.scss';

.footer-root {
  background-color: $background-color-primary;
  width: 100%;
  color: $secondary-color;
  margin-top: 25px;
  border-radius: 20px 20px 0px 0px;
  line-height: 24px;

  .footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    font-size: 12px;

    .footer-row {
      display: flex;
      align-items: center;

      .contact-us-text {
        margin-left: 10px;

        .footer-link {
          color: #ffe03680;
          cursor: pointer;
        }

        .footer-link:hover {
          text-decoration: underline;
        }
      }
    }

    .social-networks-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .footer-root {
    margin-bottom: 00px;
    padding: 10px;
    .footer-container {
      flex-direction: column;
      justify-content: center;
      padding: 0px;
      text-align: center;

      .contact-us {
        margin-bottom: 10px;
        .footer-row {
          justify-content: center;
        }
      }

      .accepted-currencies {
        margin-bottom: 10px;
      }

      .social-networks-container {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
