@import '../variable.scss';

.body-container {
  display: flex;
  align-items: center;
  flex-direction: row;

  .token-container {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 25px;
    color: $secondary-color;
    margin-right: 10px;
    text-align: center;
    width: 143px;
    height: 143px;

    .value-token {
      color: #ffe036;
    }

    .token-img {
      margin-bottom: 10px;
      width: 70%;
      max-width: 65px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-content {
      font-weight: $font-medium;
      font-size: $font-size-sm;
      line-height: 17px;
      text-align: center;
    }

    .group-btn-action {
      display: flex;
      align-items: center;

      .btn-claim {
        background: linear-gradient(270deg, #ff7e36 0%, #fe9357 100%);
        margin-right: 7px;
      }
      .btn-unstake {
        background: linear-gradient(270deg, #147fbb 0%, #36add2 101.6%);
        margin-left: 7px;
      }
      .btn {
        width: 94px;
        height: 30px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        color: $primary-color;
        cursor: pointer;
        font-size: 14px;
      }
      .btn:disabled {
        cursor: not-allowed;
      }
    }

    .count-down-container {
      display: flex;
      margin: 10px 0;

      .time-block {
        width: 35px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      .text-time {
        font-weight: 400;
        font-size: 11px;
        line-height: 30px;
        text-align: center;
        color: $secondary-color;
      }

      .count-down-item-between {
        height: 36px;
        display: flex;
        align-items: center;
        margin: 0 10px;
      }
    }
  }
}

.title {
  font-weight: $font-medium;
  font-size: $font-size-lg;
  line-height: 21px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .body-container {
    flex-direction: column;
    .token-container {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
