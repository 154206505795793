.slide-custom {
  .swiper-pagination {
    position: absolute;
    bottom: 5%;
    left: 10%;
    z-index: 10;
    display: flex;
  }
  .swiper-pagination-bullet {
    width: 16px;
    height: 3px;
    background: #d9d9d9;
    border-radius: 6px;
    margin-right: 5px;
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    background: #ffe036;
    border-radius: 6px;
  }
}
