@import '../../variable.scss';

.wrapper {
  margin-block: 20px;
  max-width: 95%;
  margin-inline: auto;
}

.tab-list {
  @include list-default();
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px;
  background: $background-gradient;
  border-radius: 50px;
  .item {
    display: flex;
    padding: 15px 22px;
    width: auto;
    height: auto;
    align-items: center;
    justify-content: center;
    font-family: 'Rubik-Medium';
    cursor: pointer;
    border-radius: 50px;
    .icon {
      display: flex;
      align-items: flex-end;
      margin-right: 10px;
    }
  }
  .item-active {
    background-color: #3c3c3c;
    color: #ffe036;
  }
}

@media (max-width: $breakpoint-tablet) {
  .wrapper {
    max-width: 100%;
  }
}

@media (max-width: $breakpoint-mobile) {
  .wrapper {
    max-width: 100%;
  }
  .tab-list {
    border-radius: 20px;
    .item {
      border-radius: 20px;
      justify-content: left;
      width: 100%;
      height: 100%;
    }
  }
}
