@import '../../variable.scss';

@mixin font-style($value) {
  line-height: 45px;
  font-family: 'Rubik-#{$value}';
}

.slide {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100%;
}

.wrapper {
  @include background-image('bg-banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: $rounded-md;
  .content {
    position: absolute;
    top: 10%;
    left: 5%;
    .title {
      font-size: 47px;
      @include font-style('Medium');
    }
    .desc {
      @include font-style('Medium');
      text-transform: uppercase;
    }
    .house-edge {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .icon {
        padding-right: 10px;
      }
    }
  }

  .action {
    position: absolute;
    bottom: 12%;
    left: 5%;
    .button-play {
      background: $background-gradient-gold;
      border: none;
      cursor: pointer;
      font-size: 16px;
      text-transform: uppercase;
      font-family: 'Rubik-Bold';
      padding: 12px 29px;
      border-radius: 75px;
      box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .slide {
    width: 95vw;
    height: 100%;
  }
}
