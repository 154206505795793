@import './variable.scss';

.staking-root {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 25px;
  .statistic-card {
    grid-column: span 2;
    .statistic-title {
      font-weight: 400;
      font-size: 18px;
      line-height: 21.33px;
      text-align: center;
      margin-bottom: 5px;
    }
    .statistic-value {
      font-weight: 500;
      font-size: 24px;
      line-height: 28.44px;
      text-align: center;
      color: #ffe036;
    }
  }
  .reward-tracker {
    grid-column: span 3;
  }
  .stake-bbet {
    grid-column: span 3;
    grid-row: span 2;
  }
  .history {
    grid-column: span 6;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .staking-root {
    grid-template-columns: 100%;
    grid-gap: 10px;
    .statistic-card {
      grid-column: span 1;
    }
    .reward-tracker {
      grid-column: span 1;
    }
    .stake-bbet {
      grid-column: span 1;
    }
    .history {
      grid-column: span 1;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
