@import './variable.scss';

.root {
  width: 100%;
  height: calc(100vh - 95px - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img {
    width: 131px;
  }
  p {
    font-size: $font-size-lg;
    line-height: 21px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .root {
    height: calc(100vh - 95px - 80px);
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
