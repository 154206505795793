@import '../variable.scss';

.root {
  background: linear-gradient(
    13.31deg,
    rgba(0, 0, 0, 0.2) 8.76%,
    rgba(96, 97, 100, 0.2) 54.18%,
    rgba(201, 201, 201, 0.182) 90.27%
  );

  padding: 1px;
  border-radius: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
    border-radius: 20px;
    height: 100%;
    width: 100%;
  }

  .padding {
    padding: 0 25px;

    .header {
      border-bottom: 1px solid #b3b3b310;
      padding: 25px 0;
    }

    .body {
      padding: 25px 0;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
