.date-picker-custom-ui {
  .react-datepicker {
    font-family: 'Rubik';
    font-size: 0.8rem;
    background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
    border: 1px solid #4a4a4a;
    border-radius: 0.3rem;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 20px;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: white;
    font-weight: bold;
    font-size: 0.944rem;
  }
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem;
  }
  .react-datepicker__header {
    text-align: center;
    background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
    border-bottom: none !important;
    border-top-left-radius: 0;
    position: relative;
    padding: 0;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px solid #4a4a4a;
    padding-bottom: 16px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    border: 1px solid transparent;
    color: white;
    margin: 0;
  }
  .react-datepicker__header {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      border: 1px solid transparent;
      color: #a2a3a4;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0;
    background-color: rgba(255, 224, 54, 0.2);;
    color: white;
    border-width: 1px;
  }
  .react-datepicker__day--range-start {
    background-color: yellow;
    color: black;
  }
  .react-datepicker__day--range-end {
    background-color: yellow;
    color: black;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0.1rem;
    background: none;
    color: white;
    border: 1px solid yellow;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
    display: none;
  }
  .react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 12px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.1rem;
    background-color: yellow;
    color: black;
    border-width: 1px;
  }
  .react-datepicker__navigation--previous {
    left: 20px;
  }
  .react-datepicker__navigation--next {
    right: 20px;
  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background: rgba(255, 224, 54, 0.2);
    color: white;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    display: inline-block;
    width: 2rem;
    line-height: 1.7rem;
    text-align: center;
    margin-top: 0.166rem;
  }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #A2A3A4;
    color: black;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: '×';
  }
}
