@import '../variable.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }

  img {
    height: 26px;
    width: 26px;
  }
}
.body-item {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;

  .body-item-key {
    color: $secondary-color;
    margin-right: 10px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
