@import '../../variable.scss';

.wrapper {
  background: $background-gradient;
  border-radius: 20px;
}

.list-game {
  @include list-default;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 1.25rem 0.75rem;
  padding: 20px;
  .item {
    margin-inline: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    // max-width: 160px;
    &-img {
      width: 100%;
      border-radius: 20px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
      .img-disable {
        filter: grayscale(1);
      }
    }
    &-desc {
      margin-top: 7px;
      margin-inline: 7px;
      font-size: $font-size-sm;
      color: $text-secondary;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .coming-soon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .list-game {
    padding: 10px;
  }
}
