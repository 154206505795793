@import '../variable.scss';

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }

  .my-balance-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #383838;
    border-radius: 27px;
    padding: 5px 15px;
    .token {
      margin-left: 10px;
      width: 24px;
      height: 24px;
    }
    .value-balance {
      color: #ffe036;
    }
  }
}

.stake-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  color: $secondary-color;

  .input-container {
    display: flex;
    align-items: center;
    .text {
      margin: 0 10px;
      .caption {
        font-size: 12px;
      }
    }
    .input {
      background-color: #0d0d0c;
      border: none;
      outline: none;
      padding: 10px;
      border-radius: 7px;
      color: $primary-color;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-right: 10px;
    }
  }

  .btn-max {
    background-color: #0d0d0c;
    border: none;
    outline: none;
    color: $secondary-color;
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}

.stake-radio-container {
  margin-bottom: 25px;
  color: $secondary-color;
}

.radio-container {
  margin: 10px 0;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 15px;
}

.note-container {
  color: $secondary-color;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  .note-title {
    font-size: 16px;
  }
}

.btn-action {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    border-radius: 20px;
    padding: 10px;
    min-height: 40px;
    min-width: 166px;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
  }
  .btn:disabled {
    cursor: not-allowed;
  }
  .btn-connect-wallet {
    background: #ffe036;
  }
  .btn-approve {
    background: #1fd1b1;
  }
  .btn-stake {
    background: linear-gradient(270deg, #0c9e4f 0%, #1bba64 101.6%);
    color: $primary-color;
  }
}

.staked-statistic {
  .stake-info {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    .info-key {
      color: $secondary-color;
      margin-right: 10px;
    }
  }
}

.stake-more-container {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 25px;
  margin-top: 20px;
  .stake-more-title {
    font-size: 16px;
  }
  .switch-option {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    margin: 15px 0;
  }
  .note-container {
    color: $secondary-color;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    ul {
      padding: 0;
      list-style-position: inside;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .stake-input-container {
    display: block;
    .input-container {
      margin-bottom: 10px;
      .input {
        width: 50vw;
      }
    }
  }

  .radio-container {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
