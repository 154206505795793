@import '../../variable.scss';

.container {
  max-width: 944px;
  margin: auto;
}

.header {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 320px;
  .banner {
    padding-right: 8px;
    width: 68%;
    height: 100%;
  }
  .top-wining {
    width: 32%;
    padding-left: 8px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .header {
    // flex-direction: column;
    height: auto;
    .banner {
      height: 290px;
      width: 100%;
      margin-bottom: 20px;
      padding-right: 0;
    }
    .top-wining {
      width: 100%;
      padding-left: 0;
    }
  }
}
