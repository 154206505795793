@import '../variable.scss';

.modal-container {
  position: fixed;
  top: 0;
  transition: 0.5s ease-in-out;
  height: 100vh;
  width: 100vw;
  z-index: 99;
}

.background {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
  opacity: 0.5;
}

.modal {
  background-color: #2c2c2c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  transition: 0.5s ease-in-out;
  .modal-header {
    padding: 16px 20px;
    display: flex;
    border-radius: 20px 20px 0 0;
    justify-content: space-between;
    .title {
      flex-grow: 1;
    }
    .modal-close-button {
      border: none;
      background: none;
      color: white;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .modal-content {
    padding: 10px 20px;
  }
  .modal-footer {
    border-top: 1px solid #343434;
    padding: 20px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .modal {
    width: 95%;
    max-height: 100%;
  }
}
