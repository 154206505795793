@import '../variable.scss';

.header-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $background-color-primary;
  width: 100%;
  height: 95px;
  position: sticky;
  top: 0;
  z-index: 40;
  padding: 0 25px;
  .header-logo {
    display: none;
  }

  .header-input-search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 5px 10px;

    .header-input-search {
      background-color: transparent;
      border: none;
      outline: none;
      color: $primary-color;

      width: 380px;
      height: 35px;
    }
  }

  .header-group-right {
    display: flex;
    align-items: center;
    .header-group-right-item {
      padding: 0 25px;
      .message {
        display: block;
      }
      .header-avatar {
        border-radius: 13.3049px;
        width: 44px;
        height: 44px;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }

  .button-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    .message {
      cursor: pointer;
    }
  }
  .button {
    font-size: $font-size-sm;
    padding: 10px 30px;
    border-radius: 7px;
    font-family: 'Rubik-Medium';
    cursor: pointer;
  }
  .button-outline {
    background: none;
    border: 2px solid #ffe036;
    color: #ffe036;
  }
  .button-primary {
    background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
    border: 2px solid #ffe036;
    color: black;
  }
  .wallet {
    display: flex;
    align-items: center;
    .wallet-desc {
      margin-right: 10px;
      .coin-name {
        display: flex;
        .name {
          margin-inline: 10px;
        }
      }
    }
    .button-wallet {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 10px;
      }
    }
  }
}

.account {
  padding: 20px;
  border-radius: 20px;
  margin-top: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
  .info {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .detail {
      display: flex;
      .desc {
        margin-left: 10px;
        .name {
          margin-bottom: 5px;
          font-size: $font-size-lg;
          font-family: 'Rubik-Medium';
        }
        .id {
          color: #a2a3a4;
          font-size: $font-size-sm;
        }
        .list-medal {
          @include list-default();
          display: flex;
          li {
            margin-right: 6px;
          }
        }
      }
    }
  }
  .list {
    @include list-default();
    background: rgba(255, 255, 255, 0.05);
    width: 317px;
    border-radius: 10px;
    padding: 10px 20px;
    li {
      padding: 10px 16px;
      display: flex;
      cursor: pointer;
      color: #a2a3a4;
      border-radius: 5px;
      .icon {
        margin-right: 10px;
      }
    }
    .security {
      display: flex;
      justify-content: space-between;
      .security-head {
        display: flex;
      }
    }
    li:hover {
      background: rgba(255, 255, 255, 0.05);
      color: white;
    }
  }
  .button-logout {
    background: none;
    border: none;
    display: flex;
    padding: 20px 0;
    color: $secondary-color;
    font-size: $font-size-md;
    cursor: pointer;
    .icon {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header-root {
    flex-direction: column;
    gap: 10px;
    padding: 0px 10px;
    flex-wrap: wrap;
    height: 0px;
    justify-content: flex-start;
    .header-logo {
      display: block;
      width: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .header-input-search-container {
      .header-input-search {
        width: auto;
        height: auto;
      }
    }
    .header-group-right {
      display: none;
      .header-group-right-item {
        .message {
          display: none;
        }
      }
    }
    .button-group {
      .message {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
