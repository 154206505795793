@import './variable.scss';

.root {
  width: 100%;
  height: calc(100vh - 100px - 115px - 60px);

  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $breakpoint-mobile) {
  .root {
    img {
      width: 80%;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
