@import '../variable.scss';

.root {
  .title-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    .text {
      padding: 0 10px;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      color: $primary-color;
    }
  }

  .card-container {
    margin: 20px 0 10px 0;
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    width: 513px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    .card-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .token-block {
        display: flex;
        align-items: center;

        .token-logo {
          margin-right: 10px;
        }
      }

      .balance {
        color: #ffe036;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .root {
    .card-container {
      width: 70vw;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
