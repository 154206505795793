@import '../variable.scss';

.sidebar-root {
  color: $secondary-color;
  min-height: 100vh;
  height: 100vh;
  width: 268px;
  background: $background-color-primary;
  padding: 0 25px;
  display: block;
  position: sticky;
  top: 0;
  left: 0;

  .logo-container {
    margin-left: 20px;
    height: 95px;
    margin-top: 16px;
  }

  .nav-link {
    color: $secondary-color;
    text-decoration: none;
    div {
      padding: 10px;
    }

    .menu-item-container {
      display: flex;
      align-items: center;
      margin: 3px 0;

      .menu-item-title {
        padding-left: 10px;
      }
    }
  }

  .activated {
    div {
      border-radius: 14px;
      background: linear-gradient(
        65.58deg,
        rgba(22, 22, 22, 0.5) -11.84%,
        rgba(102, 80, 0, 0.5) 98.75%
      );
    }
  }

  .menu-divider {
    margin: 15px 0;
  }

  .menu-collapse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .sidebar-root {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
