@import './variable.scss';

@mixin font-url($value) {
  src: url('../../public/fonts/Rubik/static/Rubik-#{$value}.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  src: url('../../public/fonts/Rubik/Rubik-VariableFont_wght.ttf') format('truetype');
}

@each $value in $fontStyles {
  @font-face {
    font-family: 'Rubik-#{$value}';
    @include font-url($value);
  }
}
