@import '../variable.scss';

.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  color: $text-secondary;
  margin-top: 20px;

  .btn-prev {
    transform: rotate(90deg);
  }

  .btn-next {
    transform: rotate(-90deg);
  }

  .btn {
    background: transparent;
    outline: none;
    border: 1px solid rgba(179, 179, 179, 0.1);
    border-radius: 5px;
    padding: 5px 7px;
    cursor: pointer;
  }

  .btn:disabled {
    opacity: 50;
    cursor: not-allowed;
  }

  .page-item {
    cursor: pointer;
    padding: 0px 10px;
  }
  .page-item-active {
    color: $text-yellow;
  }
}
