@import '../variable.scss';

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 24px;
  padding-top: 2px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    display: flex;
  }
  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background: transparent;
    border: 1px solid #1898df;
  }
  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background: transparent;
    border: 1px solid #a2a3a4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1898df;
    border: 1px solid #1898df;
  }
}

/* On mouse-over, add a grey background color */
.container:hover {
  input ~ .checkmark {
    background: transparent;
    border: 1px solid #1898df;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
