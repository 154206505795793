@import '../variable.scss';

.table-container {
  width: 100%;
  border: 1px solid rgba(179, 179, 179, 0.1);
  border-radius: 10px;
  color: $secondary-color;
  .table-header-item {
    border-bottom: 1px solid rgba(179, 179, 179, 0.1);
    padding: 10px 0;
    font-weight: 400;
    min-width: 150px;
  }
  .data-item {
    text-align: center;
    padding: 10px;
    font-weight: 400;
  }

  .nodata {
    text-align: center;

    .nodata-item {
      padding: 10px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
