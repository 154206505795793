@import '../../variable.scss';

.wrapper {
  background: $background-gradient;
  border-radius: $rounded-md;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .title-component {
    font-size: 20px;
    font-family: 'Rubik-Medium';
  }
  .list {
    padding: 0;
    margin: 0;
    list-style: none;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item {
      margin-block: 10px;
      display: flex;
      justify-content: space-between;
      &-img {
        width: 64px;
        height: 64px;
        border-radius: $rounded-md;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: $rounded-md;
        }
      }
      .desc {
        display: flex;
      }
      .content {
        padding: 0 10px;
        .desc {
          text-transform: capitalize;
          font-size: 14px;
          color: $text-secondary;
        }
      }
      .icon {
        padding-left: 10px;
        margin-top: 5px;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .wrapper {
    .list {
      .item {
        width: 100%;
      }
    }
  }
}
