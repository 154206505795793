$primary-color: #ffffff;
$secondary-color: #a2a3a4;

$background-color: #131313;

$text-secondary: #969696;

$text-yellow: #FFE036;

$background-gradient: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
$background-gradient-gold: linear-gradient(
  90deg,
  #eca740 -23.14%,
  #ffe58a 33.71%,
  #dd7617 97.78%,
  #ffee95 154.63%
);

$breakpoint-desktop: 1366px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 576px;

$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;

$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

$primary-border-color: #b3b3b3;
$rounded-md: 20px;

$spacing-xs: 5px;
$spacing-sm: 10px;
$spacing-md: 15px;
$spacing-lg: 20px;
$spacing-xl: 24px;

@mixin background-image($image) {
  background-image: url('../../public/images/#{$image}');
}

$font-weight: (
  'thin': 100,
  'extralight': 200,
  'light': 300,
  'normal': 400,
  'medium': 500,
  'semibold': 600,
  'bold': 700,
  'extrabold': 800,
  'black': 900
);

$fontStyles: 'Bold', 'Black', 'ExtraBold', 'Light', 'Medium', 'Regular', 'SemiBold';

$spacing: (
  'xs': $spacing-xs,
  'sm': $spacing-sm,
  'md': $spacing-md,
  'lg': $spacing-lg,
  'xl': $spacing-xl
);

@mixin list-default {
  list-style: none;
  padding: 0;
  margin: 0;
}

$font-size: ($fontStyles);
$padding: ($spacing);
$background-color-primary: #131313;
