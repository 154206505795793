@import './fonts.scss';
@import './variable.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-color;
  font-size: $font-size-md;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  margin: 0px;
}

input[type='checkbox'] {
  background: #444444 !important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  background: rgba(255, 255, 255, 0.1);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
button {
  font-family: 'Rubik';
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
@media screen and (max-width: $breakpoint-mobile) {
  ::-webkit-scrollbar {
    height: 8px;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
